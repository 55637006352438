import React, {memo, useCallback, useState} from "react";
import {HStack, VStack} from "../../../../ui/Stack";
import {Alert, Form, Modal, Skeleton} from "antd";
import {HSteps} from "../../../../ui/Steps";
import {DefaultJsonForm} from "../../../../components/v2/default-json-form";
import {useDispatch, useSelector} from "react-redux";
import {
    getHrFormData,
    getHrFormError,
    getHrFormLoading,
    getVisibleCancelModal,
    getVisibleFormData,
} from "../selectors";
import {useDebounce} from "../../../../hooks/useDebounce/useDebounce";
import {useHrSaveResultForms} from "../hooks/useHrSaveResultForms";
import {HrModal} from "../hr-modal";
import {isFormDataRequired} from "../../../../../lib/utils/collections";
import {nextStage} from "../../../../store/hr/action-creators";
import {HrFormsHeader} from "./hr-form-header/hr-form-header";

interface HrFormsProps {
    className?: string
    id: string | number,
}

const formItemLayout = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 8},
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 24},
    },
}


export const HrForms: React.FC<HrFormsProps> = memo((props: HrFormsProps) => {
    const {id} = props;
    const dispatch = useDispatch();
    const [form] = Form.useForm()

    const hrFormData = useSelector(getHrFormData);
    const hrFormIsLoading = useSelector(getHrFormLoading);
    const hrFormError = useSelector(getHrFormError);

    const [isOpenCancelModal, setIsOpenCancelModal] = useState(false);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [isDisabled, setIsDisabled] = useState<boolean>();

    const visibleCancelModal = useSelector(getVisibleCancelModal);
    const visibleFormData = useSelector(getVisibleFormData);

    const {saveResult} = useHrSaveResultForms();


    const saveCase = useCallback(async (isNextStage = false) => {
            await saveResult(id, visibleFormData, hrFormData?.stages?.current, isOpenModal, setIsOpenModal, isNextStage)
        },
        [history, id, visibleFormData]
    )
    const debounceSaveCase = useDebounce(saveCase, 1000);


    const onFormDataCandidate = useCallback(
        async ({formData}) => {
            if (!visibleFormData) {
                return;
            }
            const isFilesEdit = JSON.stringify(formData) === JSON.stringify(visibleFormData?.candidate);
            if (isFilesEdit) {
                return;
            }
            visibleFormData.candidate = {...formData}
            debounceSaveCase();
        }, [visibleFormData, debounceSaveCase]
    )

    const onFormDataRequest = useCallback(
        async ({formData}) => {
            if (!visibleFormData) {
                return;
            }
            const isFilesEdit = JSON.stringify(formData) === JSON.stringify(visibleFormData?.request);
            if (isFilesEdit) {
                return;
            }
            visibleFormData.request = {...formData}
            debounceSaveCase();
        }, [visibleFormData, debounceSaveCase]
    )
    const handleCancel = useCallback(() => {
        setIsOpenModal(false);
    }, [setIsOpenModal]);

    const onFormDataModal = useCallback(
        async ({formData}) => {
            if (!visibleFormData) {
                return;
            }
            const isFilesEdit = JSON.stringify(formData) === JSON.stringify(visibleFormData?.modal);
            if (isFilesEdit) {
                return;
            }
            visibleFormData.modal = {...formData}
        }, [visibleFormData]
    )

    const handleOk = useCallback(async () => {
        if (!visibleFormData) {
            return;
        }
        const isRequiredModal = isFormDataRequired(visibleFormData.modal, visibleFormData.modalFieldsSchema)
        if (!isRequiredModal) {
            Modal.warning({
                title: 'Предупреждение',
                content: 'Заполните все обязательные поля'
            })
            return
        }
        setIsDisabled(true);
        setIsOpenModal(false);
        await saveCase(true);
        setIsDisabled(false);
    }, [setIsDisabled, saveCase, visibleFormData]);

    const handleOkCancelModal = useCallback(async () => {
        if (!visibleCancelModal) {
            return;
        }
        const formData = {...visibleCancelModal.fields}
        const isRequiredFields = isFormDataRequired(formData, visibleCancelModal.schema)

        if (!isRequiredFields) {
            Modal.warning({
                title: 'Предупреждение',
                content: 'Заполните обязательные поля'
            })
            return
        }
        dispatch(nextStage(id, formData));
        setIsOpenCancelModal(false);
    }, [setIsOpenCancelModal, visibleCancelModal]);


    const handleCloseCancelModal = useCallback(() => {
        setIsOpenCancelModal(false);
    }, [setIsOpenCancelModal]);

    const handleOnClickCancelButton = useCallback(() => {
        setIsOpenCancelModal(true);
    }, [setIsOpenCancelModal]);

    const handleOnClick = useCallback(async () => {
        setIsDisabled(true);
        await saveCase(true);
        setIsDisabled(false);
    }, [isDisabled, saveCase])

    if (hrFormError && !hrFormIsLoading && !hrFormData) {
        return (
            <HStack gap={"8"} className={'mapWrapper'}>
                При выполнении запроса возникла ошибка.
                Попробуйте перезагрузить страницу или открыть карточку повторно.
            </HStack>
        )
    }

    return (
        <>
            <HrFormsHeader
                id={id}
                dateCreated={visibleFormData ? visibleFormData?.dateCreated : ''}
                stage={hrFormData?.stages?.current}
                nextStageOnClick={handleOnClick}
                onClickCancelButton={handleOnClickCancelButton}
            />
            <HStack gap={"8"} className={'mapContent'} max>
                <Form {...formItemLayout} layout="horizontal" form={form} scrollToFirstError={true}
                      style={{width: '100%'}}>
                    {hrFormError && !hrFormIsLoading ? (
                        <div className={'alertWrapper'}>
                            <Alert
                                message="При выполнении операции возникла ошибка:"
                                showIcon
                                type="error"
                                description={hrFormError}
                            />
                        </div>
                    ) : null}
                    {!hrFormIsLoading ? (
                        <VStack gap={"16"} max align={"stretch"} className={'hrForm'}>

                            {hrFormData?.stages && (
                                <HSteps items={Object.values(hrFormData?.stages?.items)}
                                        current={hrFormData?.stages?.current}
                                        history={hrFormData?.stages?.history}
                                />)
                            }

                            <HStack gap={"8"} max>
                                <span className={'h3'}>Кандидат</span>
                            </HStack>

                            {
                                visibleFormData && <DefaultJsonForm
                                    formData={{...visibleFormData.candidate}}
                                    schema={visibleFormData.candidateFieldsSchema}
                                    onChange={onFormDataCandidate}
                                    orientation="horizontal"
                                />
                            }

                            <div className={'h3'}>Обращение</div>

                            {
                                visibleFormData && <DefaultJsonForm
                                    formData={{...visibleFormData.request}}
                                    schema={visibleFormData.requestFieldsSchema}
                                    onChange={onFormDataRequest}
                                    orientation="horizontal"
                                />
                            }

                        </VStack>
                    ) : (
                        <Skeleton active/>
                    )}
                </Form>
                {
                    visibleFormData && (
                        !hrFormIsLoading && visibleFormData?.modal && (
                            <HrModal
                                schema={visibleFormData.modalFieldsSchema}
                                onChange={onFormDataModal}
                                formData={visibleFormData.modal}
                                isOpen={isOpenModal}
                                onCancel={handleCancel}
                                onOk={handleOk}
                                centered={true}
                                disabled={isDisabled}
                                title={visibleFormData?.modalTitle}
                            />
                        )
                    )
                }
                {
                    visibleCancelModal && (
                        !hrFormIsLoading && visibleCancelModal?.fields && (
                            <HrModal
                                schema={visibleCancelModal?.schema}
                                onChange={onFormDataModal}
                                formData={visibleCancelModal.fields}
                                isOpen={isOpenCancelModal}
                                onCancel={handleCloseCancelModal}
                                onOk={handleOkCancelModal}
                                centered={true}
                                disabled={isDisabled}
                                title={visibleCancelModal?.title}
                            />
                        )
                    )
                }
            </HStack>
        </>
    )
})
